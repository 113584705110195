.gantt .grid-background {
    fill: none;
}

.gantt .grid-header {
    fill: #e2e8f0;
}

.gantt .grid-row {
    fill: #ffffff;
    /* fill-opacity: 0.1; */
}
.gantt .grid-row:nth-child(even) {
    /* fill-opacity: 0.05; */
}

.gantt .row-line,
.gantt .tick {
    stroke: rgb(192, 192, 192);
    stroke-width: 1px;
}
.gantt .tick.thick {
    stroke-width: 1;
}
.gantt .today-highlight {
    fill: #cbd5e1;
    opacity: 1;
}

.gantt .arrow {
    fill: none;
    stroke: rgb(45 122 255);
    stroke-width: 2;
}

.gantt .bar {
    fill: transparent;
    user-select: none;
}

.gantt .bar-progress {
    stroke: #1c1c1c;
    stroke-width: 0;
}

/* Invalid bar (when date is not valid) */
.gantt .bar-invalid {
    fill: transparent;
    stroke: #8d99a6;
    stroke-width: 0;
    stroke-dasharray: 5;
}

.gantt .bar-invalid ~ .bar-label {
    fill: #555;
}

/**/
.gantt .bar-label {
    fill: #fff;
    stroke-width: 0;
    dominant-baseline: central;
    text-anchor: middle;
    font-size: 12px;
    font-weight: 600;
}
.gantt .bar-label.big {
    fill: #555;
    text-anchor: start;
}
.gantt .handle {
    display: none;
}
.gantt .bar-wrapper {
    /* cursor: pointer; */
    outline: none;
}
/*.gantt .bar-wrapper:hover .bar {
    fill: #a9b5c1;
}
.gantt .bar-wrapper:hover .bar-progress {
    fill: #8a8aff;
}
.gantt .bar-wrapper:hover .handle {
    visibility: visible;
    opacity: 1;
}*/
/* .gantt .bar-wrapper.active .bar {
    fill: #a9b5c1;
} */
/* .gantt .bar-wrapper.active .bar-progress {
    fill: #8a8aff;
} */
.gantt .lower-text,
.gantt .upper-text {
    font-size: 12px;
    text-anchor: middle;
}
.gantt .upper-text {
    fill: #000;
    font-weight: bold;
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
}
.gantt .lower-text {
    fill: #000;
    font-weight: bold;
    line-height: 1.75rem /* 28px */;
    line-height: 1.75rem /* 28px */;
}
.gantt .hide {
    display: none;
}

.gantt-container {
    position: relative;
    overflow: auto;
    font-size: 12px;
    overflow: hidden;
}

/* Gantt Popup */
.gantt-container .popup-wrapper {
    position: absolute;
    width: 256px;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 0;
    color: #959da5;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.gantt-container .popup-wrapper .title {
    border-bottom: 3px solid #a3a3ff;
    font-weight: 600;
    padding: 12px;
}

.gantt-container .popup-wrapper .description {
    padding-inline: 10px;
}

.gantt-container .responsible-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.gantt-container .popup-wrapper .responsible {
    padding-inline: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.gantt-container .popup-wrapper .responsible .responsible-name {
}

.gantt-container .popup-wrapper .responsible .responsible-avatar {
    width: 20px;
    height: 20px;
    border-radius: 999px;
    background: #ffffff20;
    background-image: url('https://images.unsplash.com/photo-1526800544336-d04f0cbfd700?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80');
    background-size: cover;
    background-position: center center;
}

.gantt-container .popup-wrapper .subtitle {
    padding-inline: 10px;
    padding-bottom: 10px;
    color: #dfe2e5;
}
.gantt-container .popup-wrapper .pointer {
    position: absolute;
    height: 6px;
    margin: 6px 0 0 -3px;
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.8);
}
